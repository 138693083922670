<template>
  <div v-if="!isReadyStep1">
    <IntroductionStep @next="() => (onNextStep('isReadyStep1'))" />
  </div>

  <div v-else-if="!isReadyStep2">
    <SubscribeStep @next="() => (onNextStep('isReadyStep2'))" />
  </div>

  <div v-else-if="!isReadyStep3">
    <PostAboutStep @next="() => (onNextStep('isReadyStep3'))" />
  </div>

  <div v-else-if="!isReadyStep4">
    <VoteStep @next="() => (onNextStep('isReadyStep4'))" />
  </div>
</template>

<script>
export default {
  name: 'PreRegisterStepper',
  components: {
    IntroductionStep: () => import('@/view/pages/bind/preRegisterStepper/components/IntroductionStep'),
    SubscribeStep: () => import('@/view/pages/bind/preRegisterStepper/components/SubscribeStep'),
    PostAboutStep: () => import('@/view/pages/bind/preRegisterStepper/components/PostAboutStep'),
    VoteStep: () => import('@/view/pages/bind/preRegisterStepper/components/VoteStep'),
  },

  data: function () {
    return {
      isReadyStep1: false,
      isReadyStep2: false,
      isReadyStep3: false,
      isReadyStep4: false,
    };
  },

  methods: {
    onNextStep(stepName) {
      this[stepName] = true;

      if (this.isAllStepsReady) {
        this.$emit('ready');
      }
    },
  },

  computed: {
    isAllStepsReady() {
      return this.isReadyStep1 && this.isReadyStep2 && this.isReadyStep3 && this.isReadyStep4;
    }
  }
};
</script>

<style scoped lang="scss">

</style>
